import React from "react";
import drummer from "../assets/videos/drummer.gif";

const DrumRoll = () => {
  return (
    <section className="mt-32 text-center main-color">
      <h1 className="text-4xl">We Bring You</h1>

      <div className="flex flex-col md:flex-row justify-evenly">
        <h1 className="text-3xl self-center dr-text">Drum roll please... 🥁</h1>
        <img src={drummer} alt="Drummer Boy Animation" />
      </div>
    </section>
  );
};

export default DrumRoll;
