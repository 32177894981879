import React from "react";
import { NavLink } from "react-router-dom";
import logo from "./../assets/logo/logo.svg";
import Button from "./Button";

const Navbar = () => {
  return (
    <React.Fragment>
      <nav className="nav flex justify-between p-8 sticky top-0 z-100" style={{ backgroundColor: "#FCFDFF" }}>
        <div className="navbar-brand">
          <NavLink to="/">
            <img src={logo} alt="Plotter Logo" />
          </NavLink>
        </div>
        <div style={{ fontFamily: "DM Sans", fontSize: "18px" }}>
          <NavLink to="/personal">Personal Finance</NavLink>
        </div>
        <div style={{ fontFamily: "DM Sans", fontSize: "18px" }}>
          <NavLink to="/business">Business Finance</NavLink>
        </div>
        <Button btnText="Join our Waitlist" className="p-2" />
      </nav>
      <hr className="hr-nav" />
    </React.Fragment>
  );
};

export default Navbar;
