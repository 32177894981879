import React from "react";
import girlImg from "../assets/hero/girl-3d.svg";
import circle from "./../assets/bacgrounds/circle.svg";
import Button from "./Button";

const Hero = () => {
  return (
    <header className=" flex flex-col md:flex-row justify-between main-color p-12">
      <div>
        {/* Circle BG */}
        <img src={circle} alt="Circle SVG" className="absolute circle-svg" />
        <h1 className="mt-8 hero-header leading-loose md:leading-tight md:tracking-wider">
          <span className="alt-color">Book Keeping</span> and <span className="alt-color">Accounting</span> for
          Entrepreneurs
        </h1>
        <p className="w-full md:w-3/5 mt-5">
          Manage your business finances to accounting standards without accounting skills.
        </p>
        <Button btnText="Join our Waitlist" className="p-4 mt-8 mb-8" />
      </div>
      <div className="hero-image-wrapper rounded-2xl md:relative md:p-8">
        <img src={girlImg} alt="Girl 3d for Hero section" className="md:absolute hero-image" />
      </div>
    </header>
  );
};

export default Hero;
