import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutInfo from "../components/AboutInfo";
import DrumRoll from "../components/DrumRoll";
import WhoWeAre from "../components/WhoWeAre";
import Features from "../components/Features";
import MoreFeatures from "../components/MoreFeatures";
import CTA from "../components/CTA";
import Footer from "../components/Footer";

const Landing = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero />
      <AboutInfo />
      <DrumRoll />
      <WhoWeAre />
      <Features />
      <MoreFeatures />
      <CTA />
      <Footer />
    </React.Fragment>
  );
};

export default Landing;
