import React from "react";
import { useLocation } from "react-router-dom";
import Button from "./Button";
import FeaturesImg from "./../assets/features/main.svg";
import FeaturesImg2 from "./../assets/features/main-business.svg";

const Features = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  return (
    <section className="mt-16 md:p-16 p-8 main-color flex flex-col-reverse md:flex-row">
      <div className="mt-4">
        <h1 className="text-3xl">Using Plotter you can</h1>
        <ul className="list-inside list-disc mt-5 md:w-3/5">
          <li className="p-2">
            Budget your profit to guide your spending with our financial planning and budgeting tool.
          </li>
          <li className="p-2">Easily generate account statements like balance sheets with just a push of a button.</li>
          <li className="p-2">
            You can connect your bank account to plotter so you don’t have to manually record bank transactions both
            revenue and expenses.
          </li>
        </ul>
        <Button className="plotter-btn text-white rounded px-8 py-3 mt-8" btnText="Join our Waitlist" />
      </div>
      <div className="features-image-wrapper rounded-2xl relative md:m-8 md:p-8">
        <img
          src={pathname === "/" || pathname === "/business" ? FeaturesImg2 : FeaturesImg}
          alt="Boy 3d for Features section"
          className="absolute features-image"
        />
      </div>
    </section>
  );
};

export default Features;
