import React from "react";
import Button from "./Button";

const CTA = () => {
  return (
    <section className="mt-32 text-center main-color">
      <h1 className="text-3xl">Join our Waiting List</h1>
      <p className="mt-4">
        To get <span className="alt-color">1 month free</span> subscription and{" "}
        <span className="alt-color">10% off</span> the second month when we launch
      </p>

      <Button btnText="Join our Waitlist" className="mt-8 p-4" />
    </section>
  );
};

export default CTA;
