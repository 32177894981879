import React from "react";
import logo from "./../assets/logo/logo.svg";
import instagram from "./../assets/icons/instagram.svg";
import twitter from "./../assets/icons/twitter.svg";
import linkedin from "./../assets/icons/linkedin.svg";

const Footer = () => {
  return (
    <footer className="mt-32 p-8 flex justify-between" style={{ backgroundColor: "#FCFDFF" }}>
      <img src={logo} alt="Brand Logo" />
      <div className="self-end flex gap-4 mt-1">
        <a href="https://www.linkedin.com/company/plotterng" className="footer-icons">
          <img src={linkedin} alt="Linkedin Icon" />
        </a>
        <a href="https://instagram.com/plotter_ng" className="footer-icons">
          <img src={instagram} alt="Instagram Icon" />
        </a>
        <a href="https://twitter.com/Plotter_ng" className="footer-icons">
          <img src={twitter} alt="Twitter Icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
