import React from "react";
import { useLocation } from "react-router-dom";
import Button from "./Button";
import moreFeaturesImg from "./../assets/features/main-two.svg";
import moreFeaturesImg2 from "./../assets/features/main-business-two.svg";

const MoreFeatures = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  return (
    <section className="more-features-section mt-32 p-8 md:p-16 main-color flex flex-col md:flex-row gap-8 md:gap-32">
      <div className="more-features-image-wrapper rounded-2xl relative p-1 md:p-8">
        <img
          src={pathname === "/" || pathname === "/business" ? moreFeaturesImg2 : moreFeaturesImg}
          alt="Animated Boy"
          className="absolute more-features-image"
        />
      </div>

      <div className="more-features-content-wrapper relative">
        <h1 className="text-3xl">There's more...</h1>
        <ul className="list-inside list-disc mt-5 md:w-4/5 text-justify">
          <li className="p-2">
            Have your receipts connected to your revenue and invoice connected to your inventory so you don’t have to
            take time to record them manually when you make a sale.
          </li>
          <li className="p-2">
            Did you know you may be selling your assets for a low price? Out asset manager helps you know how much your
            assets are worth when you want to sell so you don’t leave money on the table.
          </li>
          <li className="p-2">Know who you owe and who is owing you with our liability manager.</li>
          <li className="p-2">
            The best benefit is you have access to KPIs you need to make financial decisions to grow your business.
          </li>
        </ul>
        <Button className="plotter-btn text-white rounded px-8 py-3 mt-8" btnText="Join our Waitlist" />
      </div>
    </section>
  );
};

export default MoreFeatures;
