import React from "react";

const AboutInfo = () => {
  return (
    <section className="mt-32 text-center main-color">
      <h1 className="text-4xl">Problem to Solve</h1>
      <p className="w-4/5 md:w-3/5 m-auto mt-8">
        You’re a business owner and you need to keep track of money coming in, budget your profit, track expenses and do
        a lot more but you are limited to the frustrating use of pen and paper or have to pay high price for an
        accountant, worst of all you don’t have time because your business need your attention in other important
        aspects
      </p>
    </section>
  );
};

export default AboutInfo;
