import React from "react";

const Button = ({ className, btnText }) => {
  return (
    <a href="https://mailchi.mp/451281380264/plotter-wait-list">
      <button className={`plotter-btn text-white rounded w-52 ${className}`} style={{ fontFamily: "DM Sans" }}>
        {btnText}
      </button>
    </a>
  );
};

export default Button;
