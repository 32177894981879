import React from "react";
import logo from "./../assets/logo/logo.svg";
import salyRocket from "./../assets/who-we-are/saly-rocket.svg";

const WhoWeAre = () => {
  return (
    <section className="mt-32 p-8">
      <div className="flex flex-col md:flex-row justify-evenly">
        <div className="main-color md:w-2/4">
          <h1 className="text-3xl">What is Plotter?</h1>
          <p className="mt-5 text-justify">
            Plotter is an automated platform that allows small and medium sized business owners easily and conveniently
            record and manage their business and personal finances, generate financial statements and carry out proper
            planning and analysis all with little or no knowledge of accounting.
          </p>
        </div>
        <div className="logo-lg-wrapper relative border border-gray-100 rounded-2xl shadow-2xl p-20 mt-20 md:mt-2">
          <img src={logo} alt="Brand Logo" />
          <div className="rocket-img-wrapper absolute">
            <img src={salyRocket} alt="Saly Rocket" />
          </div>
        </div>
      </div>
      <p className="text-center mt-12 md:w-1/2 m-auto">
        With Plotter, activities are <span className="alt-color">automated</span> and only require
        <span className="alt-color">little</span> to <span className="alt-color">no data</span> input to get result, no
        need for <span className="alt-color">long hours</span> on your financials.
      </p>
    </section>
  );
};

export default WhoWeAre;
